<template>
<div>
    <div id="fake-container">
        <b-img id="home-banner" src="../assets/Home_Banner.png"></b-img>
    </div>
    <div class="content" v-if="user != ''">
        <b-container id="home-header" class="px-0 mx-0">
            <b-row align-h="center" class="max-100"><b>Hello {{ user.user_name }}</b></b-row>
            <b-row align-h="center" class="pb-5 max-100">{{ today }}</b-row>
        </b-container>

        <!-- DOCUMENTS LIST  -->
        <div id="documents">
            <div id="docs-header">
                <div class="docs-label">DOCUMENTS</div>
            </div>
            <div id="docs-list-container">
                <div id="docs-list">
                    <b-link class="document" @click.prevent="HomepageDocGet" :docname="homepagedoc.document_name" v-for="homepagedoc in homepagedocs" :key="homepagedoc.document_name">
                        {{ homepagedoc.document_desc }}
                    </b-link>
                    <div id="hiddendiv"></div>
                </div>

            </div>
            <div id="list-arrow-more">
                <font-awesome-icon icon="long-arrow-alt-down"></font-awesome-icon>
            </div>

        </div>

        <!-- SHORTCUTS -->
        <div id="shortcuts">
            <div id="button-header">SHORTCUTS</div>
            <b-row id="button-row">
                <div id="member-button" v-show="user.member_access === '1'">
                    <a class="home-buttons" href="#" @click.prevent="goMembers">
                        <div>
                            <img src="../assets/MEMBERS.png">
                            <div class="button-text">Members</div>
                        </div>
                    </a>
                </div>
                <div id="shop-button" v-show="user.employer_access === '1'">
                    <a class="home-buttons" href="#" @click.prevent="goShops">
                        <div>
                            <img src="../assets/EMPLOYERS.png">
                            <div class="button-text">Shops</div>
                        </div>
                    </a>
                </div>
            </b-row>
        </div>

    </div>
    <div class="footer">
    </div>

</div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons'

library.add(faLongArrowAltDown)

export default {

    name: 'home',

    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            token: 'auth/token',
            user: 'auth/user',
            homepagedocs: 'documents/homepagedocs',
        }),

        today: function() {
            var today = new Date();
            var date = today.getDate().toLocaleString("en-US", { timeZone: "America/New_York" });
            var options = {
                month: 'long',
            };
            //
            var month = new Intl.DateTimeFormat('en-US', options).format(today);
            var year = today.getFullYear()
            return 'Today is ' + month + ' ' + date + ', ' + year;

        }

    },


    mounted() {
        this.getHomepageDocs().then(() => {
            this.flashList();
        });

        this.onScroll();
        // window.scrollTo({top:0});




    },

    methods: {

        ...mapActions({
            signOutAction: 'auth/signOut',
            setShowSearch: 'members/setShowSearch',
            clearMember: 'members/clearMember',
            turnOnLoadingMsg: 'loadingmsg/turnOnLoadingMsg',
            turnOffLoadingMsg: 'loadingmsg/turnOffLoadingMsg',
            getHomepageDocs: 'documents/getHomepageDocs',
            setDoc: 'documents/setDoc',
            setPreviousPage: 'documents/setPreviousPage',
            setShowShopSearch: 'shops/setShowShopSearch',
        }),

        flashList() {
            var dlc = document.getElementById('docs-list-container');
            var lam = document.getElementById('list-arrow-more');

            if (dlc && lam) {
                setTimeout(function() {
                    if (dlc && lam) {
                        dlc.classList.add('flash');
                        lam.classList.add('flash');
                    }

                    setTimeout(function() {
                        if (dlc && lam) {
                            dlc.classList.remove('flash');
                            lam.classList.remove('flash');
                        }

                        setTimeout(function() {
                            if (dlc && lam) {
                                dlc.classList.add('flash');
                                lam.classList.add('flash');
                            }
                        }, 100)
                    }, 250)
                }, 400)

            }
        },

        onScroll() {
            window.document.getElementById('docs-list-container').addEventListener("scroll", this.hideArrow);
        },

        hideArrow() {
            var endoflist = document.getElementById('hiddendiv');
            var listarrow = document.getElementById('list-arrow-more');

            if (endoflist && listarrow) {
                endoflist.getBoundingClientRect();
                listarrow.getBoundingClientRect();

                // console.log(endoflist.bottom+' '+listarrow.bottom);
                if ((endoflist.bottom - 60) > listarrow.bottom) {
                    document.getElementById('list-arrow-more').style.visibility = 'visible';
                } else {
                    document.getElementById('list-arrow-more').style.visibility = 'hidden';
                }

            }
        },

        goMembers() {
            if (this.$route.name != 'members') {
                this.turnOnLoadingMsg().then(() => {
                    this.setShowSearch().then(() => {
                        this.$router.replace({
                            name: 'members'
                        })

                    }).then(() => {
                        if (document.getElementById('menu-modal') != null) {
                            this.$bvModal.hide('menu-modal');

                        }
                        this.turnOffLoadingMsg();

                    }) //.catch(e => { console.log(e); })

                })
            } else {
                this.turnOnLoadingMsg().then(() => {
                    this.setShowSearch().then(() => {
                        this.$bvModal.hide('menu-modal');
                        this.turnOffLoadingMsg();

                    }) //.catch(e => { console.log(e); })

                }) //.catch(e => { console.log(e); })
            }
        },

        goShops() {
            if (this.$route.name != 'shops') {
                this.turnOnLoadingMsg().then(() => {
                    this.setShowShopSearch().then(() => {
                        this.$router.replace({
                            name: 'shops'
                        }) //.catch(e => { console.log(e); })

                    }).then(() => {
                        if (document.getElementById('menu-modal') != null) {
                            this.$bvModal.hide('menu-modal');

                        }
                        this.turnOffLoadingMsg();

                    }) //.catch(e => { console.log(e); })

                })
            } else {
                this.turnOnLoadingMsg().then(() => {
                    this.setShowShopSearch().then(() => {
                        this.$bvModal.hide('menu-modal');
                        this.turnOffLoadingMsg();

                    }) //.catch(e => { console.log(e); })

                }) //.catch(e => { console.log(e); })
            }
        },

        HomepageDocGet: function(event) {
            var docname = event.target.getAttribute('docname');
            // console.log(docname);
            this.turnOnLoadingMsg().then(() => {
                this.setPreviousPage('home');
                this.setDoc(docname).then(() => {
                    this.$router.replace({
                        name: 'docs'
                    }) //.catch(e => { console.log(e); })


                }) //.catch(e => { console.log(e); })

            }) //.catch(e => { console.log(e); })

        }
    }

}
</script>


<style scoped>
#fake-container {
    position: fixed;
    top: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 40vh;
    max-height: 40vh;
    z-index: 0;
}

#home-banner {
    vertical-align: middle;
    border-style: none;
    min-height: 40vh;
    max-height: 40vh;
    min-width: 100%;
    float: right;
    z-index: 0;
}


.content {
    position: absolute;
    background-color: transparent;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    margin-top: 3.5em;
    width: 100%;
    height: calc(100vh - 5em);
    overflow-y: scroll;

}

#home-header {
    position: fixed;
    color: white;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 1.25em;
    max-width: 100%;
}


.max-100 {
    max-width: 100%;
    margin: 0 0 0 0;
    padding: 0 0 0 0;

}

#documents {
    position: fixed;
    margin-top: 6em;
    height: 50vh;
    width: 90vw;
    margin-left: 5vw;
}

#docs-header {
    margin: 0 0 0 0;
    margin-left: 5vw;
    margin-bottom: 0.5em;
    min-height: 2em;
    max-height: 2em;
    font-weight: bold;
}

.docs-label {
    color: white;
}

#docs-icon {
    min-height: 1.5em;
    max-height: 1.5em;
}

#list-arrow-more {
    max-width: 5vw;
    transform: translate(87vw, -1.5em);
    font-size: 2em;
    color: transparent;
}

#list-arrow-more.flash {
    color: #0d2365;
}

#list-arrow-more.fade {
    opacity: 1;
    color: DarkGray;
    transition: color 0.2s;
}

#docs-list-container {
    overflow-y: scroll;
    white-space: nowrap;
    height: calc(45vh - 2em);
    line-height: 2.5;
    max-width: 90vw;
}

#docs-list-container.flash {
    border-bottom: 1px solid #0d2365;
}

#docs-list-container.fade {
    opacity: 1;
    max-width: 90vw;
    border-bottom: 1px solid Gray;
    transition: border 0.2s;
}


#docs-list {
    max-width: 80vw;
    margin-left: 5vw;
}

.document {
    padding: 0;
    margin: 0;
    display: block;
    color: black;
    border-radius: 0.25em;
    border: 2px solid #0d2365;
    background-color: white;
    padding-left: .5em;
    padding-right: .5em;
    margin-bottom: 1em;
    text-decoration: none;
    max-width: 80vw;
    overflow-y: hidden;
    overflow-x: scroll;
}

#hiddendiv {
    height: 1px;
    visibility: hidden;
}

#shortcuts {
    position: fixed;
    top: calc(50vh + 9.5em);
    background-color: white;
    width: 90vw;
    padding: 0.5em;
    border: 3px solid #e0e0e0;
    margin-top: 0.5em;
    margin-left: 5vw;
    margin-bottom: 2em;
}

#button-header {
    margin: 0 0 0 0;
    transform: translateY(-50%);
    padding-top: 1em;
    padding-left: 1em;
    min-height: 2em;
    max-height: 2em;
    font-weight: bold;
}

#button-row {
    display: flex;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    justify-content: space-around;
    border-top: 2px solid #e0e0e0;
}

#member-button {
    display: inline-flex;
}

#shop-button {
    display: inline-flex;
}

.home-buttons {
    text-decoration: none;
    outline: none;
    color: black;
}

.home-buttons img {
    max-height: 12vh;
}

.button-text {
    text-align: center;
    font-size: 1em;
}

.max-100 {
    max-width: 100%;
}

.footer {
    position: fixed;
    bottom: 0;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    max-width: 100%;
    min-width: 100%;
    min-height: 1.5em;
    border-top: 1px solid white;
    z-index: 100;
    background-color: #0d2365;

}
</style>
